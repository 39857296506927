
import { IonPage, IonTitle, IonText, IonContent, IonSlides, IonSlide, IonLabel, IonSegment, IonSegmentButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import "@lottiefiles/lottie-player";
import { app } from '@/main'

export default defineComponent({
  name: 'Tutorial',
  components: { IonTitle, IonPage, IonText, IonContent, IonSlides, IonSlide, IonLabel, IonSegment, IonSegmentButton },
  data: function() {
    return {
      loggedIn: app.config.globalProperties.loggedIn,
    }
  },
  methods: {
    segmentChanged(ev) {
      this.$i18n.locale = ev.detail.value;
    },
    toLogin () {
      this.$router.push({name:'login'})
    },
    toApp () {
      this.$router.push({name:'news'})
    }
  },
  setup() {
    // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
    const slideOpts = {
      initialSlide: 1,
      speed: 400,
    };
    return { slideOpts }
  }
});

<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <ion-slides pager="true" class="slider">
        <ion-slide class="slide" id="slide-1">
          <div class="slide-container">
            <lottie-player
              autoplay
              loop
              mode="normal"
              src="https://cms.app.thb.torben.wtf/assets/2289a81a-c473-4403-a14c-0e43d7f43052"
              style="width: 150px"
            ></lottie-player>
            <h2 class="slide-title">
              {{$t('intro.ready')}}
            </h2>
            <p class="slide-text">
              {{$t('intro.welcome')}}
            </p>
          </div>
        </ion-slide>

         <ion-slide class="slide">
          <div class="slide-container">
            <lottie-player
              autoplay
              loop
              mode="normal"
              src="https://cms.app.thb.torben.wtf/assets/7f954760-a304-45a7-930d-70f1c9ed3bb8"
              style="width: 250px"
            ></lottie-player>
            <h2 class="slide-title">
              {{$t('intro.language')}}
            </h2>
            <ion-segment @ionChange="segmentChanged($event)">
              <ion-segment-button value="de">
                <ion-label color="light">Deutsch</ion-label>
              </ion-segment-button>
              <ion-segment-button value="en">
                <ion-label color="light">English</ion-label>
              </ion-segment-button>
            </ion-segment>
          </div>
        </ion-slide>

        <ion-slide class="slide" id="slide-2">
          <div class="slide-container">
            <lottie-player
              autoplay
              loop
              mode="normal"
              src="https://cms.app.thb.torben.wtf/assets/0b033ab9-e015-453a-b9e9-084a1f03d5ce"
              style="width: 250px"
            ></lottie-player>
            <h2 class="slide-title">
                {{$t('intro.great')}}
              </h2>
              <p class="slide-text">
                {{$t('intro.great_2')}}
              </p>
              <p class="slide-text">Feedback:<br><a href="mailto:">hammes@th-brandenburg.de</a></p>
          </div>
        </ion-slide>

        <ion-slide class="slide" id="slide-2">
          <div class="slide-container">
            <img class="slide-img" src="/assets/tutorial/news.jpg" alt="News Menu">
            <h2 class="slide-title">
              {{$t('intro.news_1')}}
            </h2>
            <p class="slide-text">
              {{$t('intro.news_2')}}
            </p>
          </div>
        </ion-slide>

        <ion-slide class="slide" id="slide-2">
          <div class="slide-container">
            <img class="slide-img" src="/assets/tutorial/service.jpeg" alt="Service Menu">
            <h2 class="slide-title">
              {{$t('intro.service_1')}}
            </h2>
            <p class="slide-text">
              {{$t('intro.service_2')}}
            </p>
          </div>
        </ion-slide>

        <ion-slide class="slide" id="slide-3">
          <div class="slide-container">
            <lottie-player
              autoplay
              loop
              mode="normal"
              src="https://cms.app.thb.torben.wtf/assets/7d5d421c-ac30-4fc1-9cdc-297363dac433"
              style="width: 250px"
            ></lottie-player>
            <h2 class="slide-title">
              {{$t('intro.usecase_1')}}
            </h2>
            <p class="slide-text">
              {{$t('intro.usecase_2')}}
            </p>
          </div>
        </ion-slide>

        <ion-slide class="slide">
          <div class="slide-container">
            <lottie-player
              autoplay
              loop
              mode="normal"
              src="https://cms.app.thb.torben.wtf/assets/03cd1ba2-a6c6-4a95-ac72-d51cb10f08a4"
              style="width: 250px"
            ></lottie-player>
            <h2 class="slide-title">
              {{ $t('intro.finished') }}
            </h2>

            <ion-button v-if="loggedIn = false" class="tutorial-button" color="dark" @click="toLogin()">
              {{ $t('auth.logout_login') }}
            </ion-button>

            <!-- Falls eine Person schon eingeloggt ist, muss sie nicht zum Login -->
            <ion-button v-if="loggedIn = true" class="tutorial-button" color="dark" @click="toApp()">
              {{ $t('intro.goto_app') }}
            </ion-button>
          </div>
        </ion-slide>
      </ion-slides>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonTitle, IonText, IonContent, IonSlides, IonSlide, IonLabel, IonSegment, IonSegmentButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import "@lottiefiles/lottie-player";
import { app } from '@/main'

export default defineComponent({
  name: 'Tutorial',
  components: { IonTitle, IonPage, IonText, IonContent, IonSlides, IonSlide, IonLabel, IonSegment, IonSegmentButton },
  data: function() {
    return {
      loggedIn: app.config.globalProperties.loggedIn,
    }
  },
  methods: {
    segmentChanged(ev) {
      this.$i18n.locale = ev.detail.value;
    },
    toLogin () {
      this.$router.push({name:'login'})
    },
    toApp () {
      this.$router.push({name:'news'})
    }
  },
  setup() {
    // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
    const slideOpts = {
      initialSlide: 1,
      speed: 400,
    };
    return { slideOpts }
  }
});
</script>

<style scoped>
.slider {
  height: 100%;
  color: white;
}
.slide {
  background-color: #333333;
  padding: 10px;
}
.slide-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slide-img {
  border-radius: 10px;
}
.slide-title {
  margin-top: 60px;
}
.tutorial-button {
  margin-top: 20px;
}
</style>
